import * as React from 'react'
import classNames from 'classnames'

export default function Button ({ href, bgColor, align, children }) {
  return (
    <a href={href} className={classNames(bgColor || 'bg-gray-700', align || '', 'block px-4 py-6 text-white font-bold hover:opacity-80')}>
      {children}
    </a>
  )
}

export function PrimaryButton (props) {
  return (
    <Button bgColor='bg-primary hover:opacity-80' {...props}>
      {props.children}
    </Button>
  )
}

export function SecondaryButton (props) {
  return (
    <Button bgColor='bg-secondary' {...props}>
      {props.children}
    </Button>
  )
}
