import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { CursorClickIcon, MailIcon, PhoneIcon, ChevronRightIcon } from '@heroicons/react/solid'
import TextA from '../components/TextA'
import { PrimaryButton, SecondaryButton } from '../components/Button'

const IndexPage = () => {
  return (
    <main className='xl:w-3/4 mx-auto p-4 min-h-screen flex flex-col justify-center items-center'>
      <title>CNI Support</title>
      <header className='px-2 py-4 flex flex-col items-center'>
        <a href='https://cniit.com' target='_blank' className='hover:opacity-80' rel='noreferrer'>
          <StaticImage src='../images/logo.png' alt='CNI logo' className='w-32 h-32' loading='eager' placeholder='tracedSVG' />
        </a>
        <h1 className='text-5xl font-bold py-4'>CNI Support</h1>
      </header>
      <ol className='lg:grid lg:grid-cols-3 pb-4 lg:pb-12'>
        <li className='py-2 lg:py-0 lg:px-4 2xl:px-8'><TextA href='/'><CursorClickIcon className='w-5 h-5 mr-1 inline-block' />www.cni.support</TextA></li>
        <li className='py-2 lg:py-0 lg:px-4 2xl:px-8'><TextA href='mailto:help@cniit.com'><MailIcon className='w-5 h-5 mr-1 inline-block' />help@cniit.com</TextA></li>
        <li className='py-2 lg:py-0 lg:px-4 2xl:px-8'><TextA href='tel:+18043300000'><PhoneIcon className='w-5 h-5 mr-1 inline-block' />(804) 330-0000</TextA></li>
      </ol>
      <div className='lg:grid lg:grid-cols-2 lg:divide-x-2 text-center'>
        <section className='px-16 py-8'>
          <h2 className='uppercase font-bold text-gray-500'>Existing Users</h2>
          <h3 className='text-3xl'>Access the support portal</h3>
          <p className='py-4'>Use our portal to request support or check the status of an existing request.</p>
          <PrimaryButton align='align-bottom' href='https://cniit.deskdirector.com/'>Support Portal <ChevronRightIcon className='w-6 h-6 inline-block' /></PrimaryButton>
        </section>
        <section className='px-16 py-8'>
          <h2 className='uppercase font-bold text-gray-500'>Live Support</h2>
          <h3 className='text-3xl'>Connect with a technician</h3>
          <p className='py-4'>If directed by a technician, choose the following option to start a connection.</p>
          <SecondaryButton align='align-bottom' href='https://cniit.screenconnect.com/'>Remote Session <ChevronRightIcon className='w-6 h-6 inline-block' /></SecondaryButton>
        </section>
      </div>
    </main>
  )
}

export default IndexPage
